<template>
  <div class="data-detail" >
    <div class="det-title flex-start">
      <el-button @click="back" size="mini">返回</el-button>
      <div v-if="queryId" class="title">编辑数据源</div>
      <div v-else class="title">新增数据源</div>
    </div>
    <div class="det-content" v-loading.fullscreen.lock="fullscreenLoading">
      <el-form :model="ruleForm" size="small" label-position="top" v-loading="loading" :rules="rules"  ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="数据源名称" prop="api_name">
          <el-input v-model="ruleForm.api_name"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="ruleForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="选择类型" prop="api_type" class="no-color">
          <el-radio-group v-model="ruleForm.api_type">
            <el-radio :label="1">API接口<span class="fz12 ef82" @click="dialogVisible=true">(查看接口规范文档)</span></el-radio>
            <el-radio :label="2">名冠天下API<span class="fz12 ef82" @click="showlink=true">(联系客服)</span></el-radio>
<!--            <el-radio :label="3">数据库</el-radio>-->
          </el-radio-group>
        </el-form-item>
        <el-form-item class="star" label="数据源模式" prop="type">
          <el-button size="mini" type="primary" @click="dialogType=true">选择模式</el-button>
          {{modelTypeName}}
        </el-form-item>
        <el-form-item label="" prop="api_path">
          <template v-slot:label>
            API地址<span class="fz12 ef82">（默认请求端口：http为80，https为443，如果对应协议不为默认端口，请在接口域名后加上端口号）</span>
          </template>
          <el-input v-model="ruleForm.api_path" placeholder="包含http:// 或 https://"></el-input>
        </el-form-item>
        <div>
           <div class="field fz14 table-star" style="line-height:32px;padding: 0 0 10px;display:flex;">
              请求周期<span class="fz12 ef82">（单位：秒，默认1800秒，-1表示只请求一次，无需周期性请求，修改后需要重启模板才能生效）</span>
          </div>
          <el-input v-model="ruleForm.circle_time" placeholder="请输入"></el-input>
        </div>
       
        <el-form-item label="传参方式" prop="api_method">
          <el-radio-group v-model="ruleForm.api_method">
            <el-radio :label="1">get</el-radio>
            <el-radio :label="2">post</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="永久秘钥" prop="access_token">
          <el-input v-model="ruleForm.access_token"></el-input>
        </el-form-item>
        <div class="flex-start ip-tips">
          <i class="iconfont icon-jinggao"></i>
          <div class="ip-tipsss fz14">提示：如果接口需要设置ip白名单，请<el-button type="text"  @click="showlink=true">联系客服</el-button>获取ip</div>
        </div>
        <div style="margin-bottom: 10px">
          <span class="is-open fz14">用户唯一识别ID</span>
          <el-switch
              v-model="isOpen"
              active-text="开启"
              inactive-text="不开启">
          </el-switch>
        </div>
        <el-form-item v-show="isOpen">
          <div class="only-id">
            <div  class="distinguish-id">我方使用ID</div>
            <el-select disabled placeholder="UserId" v-model="ruleForm.myUserId">
              <el-option label="UserId" value="UserId"></el-option>
            </el-select>
          </div>
          <div class="only-id">
            <div>对方使用ID<span class="ef82 fz12 pointer"  @click="goToExport">(马上去导入)</span></div>
            <el-select @change="getThisKey" v-model="ruleForm.api_user_attr" placeholder="请选择第三方用户id">
              <el-option
                  v-for="item in othenIdList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
          <div style="margin-bottom: 10px" v-show="isOpen" >
          <span class="is-open fz14">批量请求</span>
            <el-switch
              v-model="ruleForm.is_batch_request"
              active-text="开启"
              inactive-text="关闭">
          </el-switch>
        </div>
      </el-form>
      <div class="field fz14">header入参参数</div>
      <el-table :data="tableData3" border style="width: 100%">
        <el-table-column prop="thekey" label="静态字段名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.thekey" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="thevalue" label="静态字段值">
          <template slot-scope="scope">
            <el-input v-model="scope.row.thevalue" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="" width="40" >
          <template slot-scope="scope">
            <i class="iconfont icon-guanbi del-line" @click="delLine(scope.$index,scope.row,3)"></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-field fz14" @click="addField(3)">
        <i class="iconfont icon-tianjiazengjiajia"></i>添加字段
      </div>

      <div class="field fz14">入参字段映射</div>
      <el-table :data="tableData1" border style="width: 100%">
        <el-table-column prop="thekey" label="静态字段名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.thekey" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="thevalue" label="静态字段值">
          <template slot-scope="scope">
            <el-input v-model="scope.row.thevalue" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
<!--        <el-table-column prop="thevalue"  label="对方字段值">-->
<!--          <template slot-scope="scope">-->
<!--            <el-input :disabled="scope.row.is_fixed==0" v-model="scope.row.thevalue" placeholder="请输入"></el-input>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="status" label="" width="40" >
          <template slot-scope="scope">
            <i class="iconfont icon-guanbi del-line" @click="delLine(scope.$index,scope.row,1)"></i>
          </template>
        </el-table-column>

      </el-table>
      <div class="add-field fz14" @click="addField(1)">
        <i class="iconfont icon-tianjiazengjiajia"></i>添加字段
      </div>
      <div class="field fz14 table-star">出参字段映射<span class="ip-tipsss fz14">（验证成功后将自动补全字段）</span>
      </div>
      <el-table :data="tableData2" border style="width: 100%">
        <el-table-column prop="intMyName" label="对方字段名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.outMyName" :disabled="queryId!==null" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="intOtherName" label="字段说明">
          <template slot-scope="scope">
            <el-input v-model="scope.row.outOtherName" placeholder="请输入"></el-input>
          </template>
        </el-table-column>
<!--        <el-table-column prop="status" align="center" label="" width="40" >-->
<!--          <template slot-scope="scope">-->
<!--            <i class="iconfont icon-guanbi del-line" @click="delLine(scope.$index,scope.row,2)"></i>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
<!--      <div v-if="queryId" class="add-field fz14" @click="addField(2)">-->
<!--        <i class="iconfont icon-tianjiazengjiajia"></i>添加字段-->
<!--      </div>-->
      <div class="det-btns flex-cent">
        <el-button size="mini" type="primary" v-show="step===1" @click="checkApp('ruleForm',1)">验证</el-button>
        <el-button size="mini" class="checking-btn" v-show="step===2" :loading="checkLoading">验证中</el-button>
        <div v-show="step===2" class="fz12 check-msg">请耐心等待</div>
        <el-button  v-show="step===5" class="checking-btn" size="mini">验证通过</el-button>
        <el-button  v-show="step===3" size="mini" type="danger">未通过</el-button>
<!--        <div v-show="step===3" class="fz12 check-msg">请检查数据源</div>-->
        <el-button size="mini" :loading="checkSubmitLoading" class="check-and-submit" type="primary" @click="checkApp('ruleForm',2)">验证并提交</el-button>
      </div>
    </div>
<!--    选择模式弹窗-->
    <el-dialog class="type-dialog" title="选择模式" :visible.sync="dialogType" width="650px">
        <div class="model-list flex-bet" style="flex-wrap: wrap;">
          <div class="model-item flex-start-col" v-for="(item,index) in modelList1" :key="item.id">
            <div class="model-title flex-start">
              <div class="fz14">{{item.name}}</div>
              <div class="title-desc fz12">{{item.desc}}</div>
            </div>
            <div class="select-img"><img :src="item.img" alt="模板" class="img-center"></div>
            <el-button size="mini" type="primary" @click="selectModel(item,index)">选择</el-button>
          </div>
          <div class="model-item flex-start-col" v-for="(item,index) in modelList2" :key="item.id">
            <div class="model-title flex-start">
              <div class="fz14">{{item.name}}</div>
              <div class="title-desc fz12">{{item.desc}}</div>
            </div>
            <div class="select-img2"><img :src="item.img" alt="模板" class="img-center"></div>
            <el-button size="mini" type="primary" @click="selectModel(item,index)">选择</el-button>
          </div>
        </div>
      <div>

      </div>
    </el-dialog>

    <el-dialog title="接口规范文档" :visible.sync="dialogVisible" width="800px" class="doc-dialog">
        <div class="show-doc">
          <div class="fz20 doc-title">API接口规范文档</div>
          <div class="fz16 doc-msg">API接口是指需要对接到名冠自定义工作台得数据接口，支持其他系统根据需要按指定规则编写接口。<br/>统一规则：</div>
          <div class="flex-start">
            <div class="blue-circle"></div>
            <div>1、返回数据格式为json型，主要结构为：{“code”：200，“data”=＞{}}}</div>
          </div>
          <div class="flex-start">
            <div class="blue-circle"></div>
            <div>2、必须返回code参数，且code=200表示请求成功，其他值均为失败</div>
          </div>
          <div class="flex-start">
            <div class="blue-circle"></div>
            <div>3、请保证接口在5s内返回值，否则可能会请求失败导致数据无法及时更新</div>
          </div>
          <div class="pt24">具体接口规则如：</div>
          <div class="code-tips">1、关键数据型：其接口返回数据格式为：</div>
          <div class="code-show">
            <div class="level level1">{</div>
            <div class="level level2">"code": 200,</div>
            <div class="level level2">"data": [</div>
            <div class="level level3">{</div>
            <div class="level level4">"key": "待审批",</div>
            <div class="level level4">"data": "2",</div>
            <div class="level level4">"jump_url": "http://www.qq.com",</div>
            <div class="level level4">"pagepath": "pages/index"</div>
            <div class="level level3">},</div>
            <div class="level level3">{</div>
            <div class="level level4">"key": "带批阅作业",</div>
            <div class="level level4">"data": "4",</div>
            <div class="level level4">"jump_url": "http://www.qq.com",</div>
            <div class="level level4">"pagepath": "pages/index"</div>
            <div class="level level3">},</div>
            <div class="level level3">{</div>
            <div class="level level4">"key": "成绩录入",</div>
            <div class="level level4">"data": "45",</div>
            <div class="level level4">"jump_url": "http://www.qq.com",</div>
            <div class="level level4">"pagepath": "pages/index"</div>
            <div class="level level3">},</div>
            <div class="level level3">{</div>
            <div class="level level4">"key": "综合评价",</div>
            <div class="level level4">"data": "98",</div>
            <div class="level level4">"jump_url": "http://www.qq.com",</div>
            <div class="level level4">"pagepath": "pages/index"</div>
            <div class="level level3">}</div>
            <div class="level level2">]</div>
            <div class="level level1">}</div>
          </div>
          <div class="param-info">参数说明：</div>
          <el-table :data="keydata1" border style="width: 100%">
            <el-table-column prop="param" label="参数" width="130"></el-table-column>
            <el-table-column prop="necessary" label="必须" width="65"></el-table-column>
            <el-table-column prop="tips" label="说明"></el-table-column>
          </el-table>
          <div class="code-tips">2、图片型</div>
          <div class="code-show">
            <div class="level level1">{</div>
            <div class="level level2">"code": 200,</div>
            <div class="level level2">"data": [</div>
            <div class="level level3">{</div>
            <div class="level level4">"url": "xxxx",</div>
            <div class="level level4">"jump_url": "http://www.qq.com",</div>
            <div class="level level4">"pagepath": "pages/index"</div>
            <div class="level level3">}</div>
            <div class="level level2">]</div>
            <div class="level level1">}</div>
          </div>
          <div class="param-info">参数说明：</div>
          <el-table :data="imagedata" border style="width: 100%">
            <el-table-column prop="param" label="参数" width="130"></el-table-column>
            <el-table-column prop="necessary" label="必须" width="65"></el-table-column>
            <el-table-column prop="tips" label="说明"></el-table-column>
          </el-table>
          <div class="code-tips">3、列表型</div>
          <div class="code-show">
            <div class="level level1">{</div>
            <div class="level level2">"code": 200,</div>
            <div class="level level2">"data": [</div>
            <div class="level level3">{</div>
            <div class="level level4">"title":"智慧校园新版设计的小程序要来啦",</div>
            <div class="level level4">"jump_url":"http://www.qq.com",</div>
            <div class="level level4">"pagepath":"pages/index"</div>
            <div class="level level3">},</div>
            <div class="level level3">{</div>
            <div class="level level4">"title":"植物百科，这是什么花",</div>
            <div class="level level4">"jump_url":"http://www.qq.com",</div>
            <div class="level level4">"pagepath":"pages/index"</div>
            <div class="level level3">},</div>
            <div class="level level3">{</div>
            <div class="level level4">"title":"周一升旗通知，全体学生必须穿校服",</div>
            <div class="level level4">"jump_url":"http://www.qq.com",</div>
            <div class="level level4">"pagepath":"pages/index"</div>
            <div class="level level3">}</div>
            <div class="level level2">]</div>
            <div class="level level1">}</div>
          </div>
          <div class="param-info">参数说明：</div>
          <el-table :data="listdata" border style="width: 100%">
            <el-table-column prop="param" label="参数" width="130"></el-table-column>
            <el-table-column prop="necessary" label="必须" width="65"></el-table-column>
            <el-table-column prop="tips" label="说明"></el-table-column>
          </el-table>
          <div class="code-tips">4、webview型</div>
          <div class="code-show">
            <div class="level level1">{</div>
            <div class="level level2">"code": 200,</div>
            <div class="level level2">"data": [</div>
            <div class="level level3">{</div>
            <div class="level level4">"url":"http://www.qq.com",</div>
            <div class="level level4">"jump_url":"http://www.qq.com",</div>
            <div class="level level4">"pagepath":"pages/index"</div>
            <div class="level level3">}</div>
            <div class="level level2">]</div>
            <div class="level level1">}</div>
          </div>
          <div class="param-info">参数说明：</div>
          <el-table :data="webview" border style="width: 100%">
            <el-table-column prop="param" label="参数" width="130"></el-table-column>
            <el-table-column prop="necessary" label="必须" width="65"></el-table-column>
            <el-table-column prop="tips" label="说明"></el-table-column>
          </el-table>
        </div>
    </el-dialog>

    <link-service :show.sync="showlink"></link-service>
  </div>
</template>

<script>
import LinkService from "@/components/linkService";
import {addSource,apiDetail,editSource,otherIdList,checkApi} from '@/api/workbench'
export default {
  name: "DataDetail",
  components: {LinkService},
  data(){
    return{
      keydata1:[
        {param:'data',necessary:'是',tips:'关键数据列表，不超过4个'},
        {param:'data.key',necessary:'是',tips:'关键数据名称'},
        {param:'data.data',necessary:'是',tips:'关键数据,只支持数字型'},
        {param:'data.jump_url',necessary:'是',tips:'点击跳转url，若不填且应用设置了主页url，则跳转到主页url，否则跳到应用会话窗口'},
        {param:'data.pagepath',necessary:'否',tips:'若应用为小程序类型，该字段填小程序pagepath，若未设置，跳到小程序主页'},
      ],
      imagedata:[
        {param:'url',necessary:'是',tips:'图片url。图片的最佳比例为3.35:1'},
        {param:'jump_url',necessary:'是',tips:'点击跳转url。若不填且应用设置了主页url，则跳转到主页url，否则跳到应用会话窗口'},
        {param:'pagepath',necessary:'否',tips:'若应用为小程序类型，该字段填小程序pagepath，若未设置，跳到小程序主页'},
      ],
      listdata:[
        {param:'data',necessary:'是',tips:'关键数据列表，不超过3个'},
        {param:'data.title',necessary:'是',tips:'列表显示文字，不超过128个字节'},
        {param:'data.jump_url',necessary:'是',tips:'点击跳转url，若不填且应用设置了主页url，则跳转到主页url，否则跳到应用会话窗口'},
        {param:'data.pagepath',necessary:'否',tips:'若应用为小程序类型，该字段填小程序pagepath，若未设置，跳到小程序主页'},
      ],
      webview:[
        {param:'url',necessary:'是',tips:'渲染展示的url'},
        {param:'jump_url',necessary:'是',tips:'点击跳转url。若不填且应用设置了主页url，则跳转到主页url，否则跳到应用会话窗口'},
        {param:'pagepath',necessary:'否',tips:'若应用为小程序类型，该字段填小程序pagepath，若未设置，跳到小程序主页'},
      ],
      queryId:null,
      loading:false,
      isOpen:false,
      checkFlag:false,
      showlink:false,
      checkSubmitLoading:false,
      dialogVisible:false,
      ruleForm:{
        api_name:'',
        remark:'',
        api_path:'',
        api_method:1,
        access_token:'',
        type:'',
        api_type:1,
        field_set:[],
        header_field:[],
        field_get:null,
        api_user_attr:'',
        api_user_attrname:'',
        is_batch_request:0,
        circle_time:1800.//数据源请求周期，单位（秒）：默认1800s，最低900s，-1表示不重复只请求一次，必须是1800的整数倍
      },
      dialogType:false,//模式弹窗
      isThisClose:false,
      fullscreenLoading: false,//全屏loading
      modelTypeName:'',//模式名
      modelList1:[//模板类型
        {id:"keydata",name:'关键数据型',desc:'适用于需要及时透露的关键数据',img:require('@/assets/images/model1.png')},
        {id:"image",name:'图片型',desc:'适用于通知宣传展示',img:require('@/assets/images/model2.png')},
      ],
      modelList2:[
        {id:"list",name:'列表型',desc:'适用于多条并列内容',img:require('@/assets/images/model3.png')},
        {id:"webview",name:'web-view型',desc:'承载网页的容器，适用于展示复杂内容',img:require('@/assets/images/model4.png')},
      ],
      checkLoading:false,//验证按钮
      step:1,//验证步骤
      rules: {
        api_name: [
          { required: true, message: '请输入数据源名称', trigger: 'blur' },
          { min: 2, max: 200, message: '长度在 2 到 200 个字符', trigger: 'blur' }
        ],
        api_method: [
          { required: true, message: '请选择传参方式', trigger: 'change' }
        ],
        api_type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        access_token:[
          { required: true, message: '请输入秘钥', trigger: 'blur' },
          { min: 5, max: 200, message: '长度在 5 到 200 个字符', trigger: 'blur' }
        ],
        api_path:[
          { required: true, message: '请输入数据源api地址', trigger: 'blur' },
          { min: 10, max: 150, message: '长度在 10 到 150 个字符', trigger: 'blur' }
        ],
      },
      tableData3:[//header入参参数
        {thekey: '',thevalue:''},
      ],//入参
      tableData1:[//入参字段
        {thekey: '',thevalue:''},
      ],//入参
      tableData2:[//出参字段
        {outMyName: '',outOtherName :'',},
      ],//出参
      othenIdList:[],//其他用户id属性表
    }
  },
  created() {
    this.queryId=this.$route.query.id;
    if(this.queryId) this.getDetail(this.queryId)
    this.getOtherId()
  },
  methods:{
    goToExport(){
      this.$router.push('/home/dept')
    },
    back(){//返回
      this.$router.push('/home/datamana/index')
    },
    getThisKey(val){
      console.log(val)
      this.othenIdList.map(item=>{
        if(item.id===val){
          this.ruleForm.api_user_attrname=item.key_name
        }
      })
    },
    getOtherId(){
      otherIdList().then(res=>{
        if(res.code==200){
          this.othenIdList=res.data
        }
      })
    },
    getDetail(id){
      const that=this;
      that.loading=true;
      that.tableData1=[];
      that.tableData2=[];
      apiDetail({id:id}).then(res=>{
        if(res.code==200){
          let {access_token,api_method,api_name,api_path,api_type,remark,field_get,field_set,header_field,type,api_user_attr,api_user_attrname,circle_time,is_batch_request}=res.data;
          that.ruleForm.api_name=api_name;
          that.ruleForm.remark=remark;
          that.ruleForm.api_path=api_path;
          that.ruleForm.api_method=api_method;
          that.ruleForm.api_user_attr=api_user_attr;
          that.ruleForm.api_user_attrname=api_user_attrname;
          that.ruleForm.access_token=access_token;
          that.ruleForm.type=type;
          that.ruleForm.circle_time = circle_time
          that.ruleForm.is_batch_request = is_batch_request==1?true:false
          that.modelList1?.map(item=>{
            if(type==item.id){
              that.modelTypeName=item.name;
            }
          })
          that.modelList2?.map(item=>{
            if(type==item.id){
              that.modelTypeName=item.name;
            }
          })
          that.ruleForm.api_type=api_type;
          that.ruleForm.field_get=field_get;
          header_field?.map(item=>{
            that.tableData3.push({
              thekey: item.thekey,
              thevalue:item.thevalue
            })
          })
          field_set?.map(item=>{
            that.tableData1.push({
              thekey: item.thekey,
              thevalue:item.thevalue
            })
          })
          for( let keys in field_get){
            that.tableData2.push({
              outMyName:keys,
              outOtherName:field_get[keys],
            })
          }
          that.ruleForm.header_field=that.tableData3;
          that.ruleForm.field_set=that.tableData1;

          // chenqinglong 2021-03-17
          api_user_attr!=''? that.isOpen = true : that.isOpen = false;
        }else{
          that.$message({
            message: res.message,
            type: 'warning'
          })
          return false;
        }
        that.loading=false;
      }).catch(err=>{
        console.log(err)
        that.loading=false;
      })
    },
    addField(type){//添加一行字段
      if(type===1){
        const newValue = {
          thekey: '',
          thevalue:'',
        };
        //添加新的行数
        this.tableData1.push(newValue);
      }else if(type==3){ // header 入参参数
        const newValue = {
          thekey: '',
          thevalue:'',
        };
        //添加新的行数
        this.tableData3.push(newValue);
      }else{
        const newValue = {
          outMyName: '',
          outOtherName: '',
        };
        //添加新的行数
        this.tableData2.push(newValue);
      }
    },
    delLine(index,row,type) { //删除行数
      if(type===1){
        this.tableData1.splice(index, 1)
        // if(this.tableData1.length===1){
        //   return false
        // }else{
        //   this.tableData1.splice(index, 1)
        // }
      }else if(type==3){ // header 入参参数
        this.tableData3.splice(index, 1)
      }else{
        if(this.tableData2.length===1){
          return false
        }else{
          this.tableData2.splice(index, 1)
        }
      }

    },
    selectModel(item,index){//选择模式
      console.log(item,index)
      this.modelTypeName=item.name;
      this.ruleForm.type=item.id;
      this.dialogType=false;
    },
    //判断一个数是不是正整数
    isPositiveNum(s){
      var re = /^[0-9]*[1-9][0-9]*$/ ;
　　  return re.test(s)
    },
    checkApp(formName,checktype){//验证
      let flag=true;
      let flagKey=true;
      const that=this;
      that.ruleForm.field_set=[];
      that.ruleForm.header_field=[];
      const reg=/^(?!_)(?!.*?_$)[a-zA-Z0-9_]+$/;
      const allNum=/^\d+$/;
      this.$refs[formName].validate((valid) => {
        let flagAll=true;
        if(that.ruleForm.api_name.trim().length<2 || that.ruleForm.api_path.trim().length<10 || that.ruleForm.access_token.trim().length<5){
          flagAll=false;
        }
        let tabData2={};
        this.tableData2.forEach(item=>{//出参字段验证
          if(item.outMyName.trim() && item.outOtherName.trim()){
            if(allNum.test(item.outMyName.trim()) || allNum.test(item.outOtherName.trim())){
              flagKey=false;
            }
            tabData2[item.outMyName.trim()]=item.outOtherName.trim()
          }else{
            flagKey=false;
          }
        })
        this.tableData3.forEach(item=>{//header入参字段验证
          if(item.thevalue.trim() && item.thekey.trim()){
            that.ruleForm.header_field.push({
              thekey:item.thekey.trim(),
              thevalue:item.thevalue.trim()
            });
          }
        })
        this.tableData1.forEach(item=>{//入参字段验证
          if(item.thevalue.trim() && item.thekey.trim()){
            that.ruleForm.field_set.push({
              thekey:item.thekey.trim(),
              thevalue:item.thevalue.trim()
            });
          }
        })
        // if(this.ruleForm.circle_time != -1 && !this.isPositiveNum(this.ruleForm.circle_time/900)){
        //   this.$message({
        //     message: '请求周期格式不正确',
        //     type: 'warning'
        //   })
        //   return false;
        // }
        if(!that.ruleForm.type){
          this.$message({
            message: '请选择数据源模式',
            type: 'warning'
          })
          return false;
        }
        
        // if(!flag){
        //   this.$message({
        //     message: '请正确填写入参字段名',
        //     type: 'warning'
        //   })
        //   return false;
        // }
        // if(!flagKey){
        //   this.$message({
        //     message: '请正确填写出参字段名',
        //     type: 'warning'
        //   })
        //   return false;
        // }
        if(!flagAll){
          this.$message({
            message: '请正确填写表单',
            type: 'warning'
          })
          return false;
        }
        if (valid) {
          that.fullscreenLoading=true;
          that.checkLoading=true
          that.step=2;
          that.ruleForm.api_name=that.ruleForm.api_name.trim()
          that.ruleForm.api_path=that.ruleForm.api_path.trim()
          that.ruleForm.access_token=that.ruleForm.access_token.trim()
          that.ruleForm.field_get=JSON.stringify(tabData2);
          !that.isOpen ? delete that.ruleForm.api_user_attr : '';
           checkApi(that.ruleForm).then(res=>{
             console.log('成功')
              that.step=1;
              let newTable={}
              if(!that.tableData2[0] || res.data.length>that.tableData2.length){
                that.tableData2=[];
                res.data?.map(item=>{
                  console.log(item);
                  that.tableData2.push(
                      {outMyName:item,outOtherName:item}
                  )
                })
              }
              that.tableData2.forEach(item=>{
                newTable[item.outMyName]=item.outOtherName
              })
              that.ruleForm.field_get=JSON.stringify(newTable)
              if(checktype===2){
                this.submitSource()
              }else{
                that.$message({
                  type:'success',
                  message:'校验通过'
                })
                that.fullscreenLoading=false;
              }

            that.checkLoading=false;
          }).catch(err=>{
             console.log(err,'errerrerrerr')
            that.step=3;
            that.checkLoading=false;
            that.fullscreenLoading=false;
            setTimeout(()=>{
              that.step=1;
            },3000)
          })
        } else {
          return false;
        }
      });
    },
    submitSource(){//确定提交
      const that=this;
      if(that.queryId){//编辑
        // this.fullscreenLoading=true;
        that.ruleForm.id=that.queryId;
        that.ruleForm.is_batch_request=that.ruleForm.is_batch_request==true?1:0
        console.log(that.ruleForm,'xxxxxxxxxxxxxxxx')
        editSource(that.ruleForm).then(res=>{
          console.log(res)
          if(res.code==200){
            that.$message({
              type:'success',
              message:'修改成功！'
            })
            setTimeout(()=>{
              that.$router.replace('/home/datamana/index')
            },1500)
          }
        }).catch(err=>{
          that.fullscreenLoading=false;
          console.log(err)
        })
      }else{//新增
        addSource(that.ruleForm).then(res=>{
          console.log(res)
          if(res.code==200){
            that.$message({
              type:'success',
              message:'添加成功！'
            })
            setTimeout(()=>{
              that.$router.replace('/home/datamana/index')
            },1500)
          }
      that.checkSubmitLoading=false;
          that.fullscreenLoading=false;
        }).catch(err=>{
          that.fullscreenLoading=false;
          that.checkSubmitLoading=false;
          console.log(err)
        })
      }

    },
  }
}
</script>

<style lang="less" scoped>
.is_batch_request_call{
  display: inline-block;
}
.data-detail{
  .det-title{
    padding: 0 24px;
    height: 48px;
    background: #F8F9FA;
    border-bottom: 1px solid #DBDBDB;
    .title{
      flex:1;
      text-align: center;
    }
  }
  .det-content{
    padding: 44px 240px;
    .table-star:before{
      content: '*';
      color: #F56C6C;
      margin-right: 4px
    }
    /deep/.demo-ruleForm{
      //width: 100%;
      /deep/.no-color{
        /deep/.el-radio__label{
          color:#333;
        }
      }
      /deep/.el-form-item__label{
        padding-bottom: 3px;
      }
      .star{
        .el-form-item__label:before{
          content: '*';
          color: #F56C6C;
          margin-right: 4px
        }
      }

    }
    .only-id{
      display: inline-block;
      margin-right: 40px;
    }
    .distinguish-id{
      margin-right: 20px;
    }
    .ip-tips{
      margin-bottom: 30px;
      .icon-jinggao{
        color:#004580;
        font-size: 18px;
        font-weight: bold;
      }
      .ip-tipsss{
        font-weight: 500;
        color: #909499;
        margin-left: 12px;
      }
    }
    .is-open{
      color:#606266;
      padding-right: 40px;
    }
    .field{
      color:#606266;
      padding:18px 0 8px;
    }
    /deep/.el-table{
      .cell{
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .fix-input{
        margin-left: 8px;
      }
      .del-line{
        cursor: pointer;
      }

    }
    .add-field{
      border:1px solid #EBEEF5;
      border-top: none;
      padding: 13px 12px;
      color:#6699CC;
      cursor: pointer;
      i{
        color:#6699CC;
        margin-right: 5px;
      }
    }
    .table-star{
      .ip-tipsss{
        font-weight: 500;
        color: #909499;
        margin-left: 12px;
      }
    }
    .det-btns{
      padding-top: 32px;
      .checking-btn{
        background: #33DCAE;
        color:#fff;
      }
      .check-and-submit{
        margin-left: 10px;
      }
      .check-msg{
        margin-left: 12px;
      }
    }
  }
  /deep/.type-dialog{
    /deep/.el-dialog__header{
      font-weight: 500;
      padding:10px 20px;
      border-bottom: 1px solid #F5F5F5;
      /deep/.el-dialog__title{
        font-size: 16px;
      }
    }
    /deep/.el-dialog__body{
      .model-list{
        .model-item{
          margin-right: 15px;
          .model-title{
            padding: 16px 0 12px;
            .title-desc{
              color: #B7B7B7;
              margin-left: 10px;
            }
          }
          .select-img{
            width: 280px;
            height: 124px;
            margin-bottom: 16px;
          }
          .select-img2{
            width: 280px;
            height: 172px;
            margin-bottom: 16px;
          }
        }
      }


    }
  }

}
.doc-dialog{
  /deep/.el-dialog__body{
    padding: 10px 24px 30px;
  }
  .show-doc{
    max-height: 500px;
    overflow: auto;
    .doc-title{
      font-weight: bold;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .doc-msg{
      padding:20px 0 16px;
    }
    .blue-circle{
      width: 6px;
      height: 6px;
      background: #0082EF;
      border-radius: 50%;
      margin-right: 7px;
    }
    .pb24{
      padding: 10px 0 24px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .pt24{
      padding-top: 24px;
    }
    .code-tips{
      padding-top: 16px;
    }
    .code-show{
      width: 100%;
      background: #F5F5F5;
      border: 1px solid #DDE1E6;
      margin-top: 8px;
      padding: 10px;
      .level2{
        margin-left: 20px;
      }
      .level3{
        margin-left: 40px;
      }
      .level4{
        margin-left: 60px;
      }
    }
    .param-info{
      padding: 10px 0;
    }
  }
  .show-doc::-webkit-scrollbar {
    width: 0;
  }
}



</style>